<template>
  <div id="user-list">
    <!-- app drawer -->
    <!-- <model-schedule-aside
      v-if="formReady"
      v-model="isAddNewModelScheduleSidebarActive"
      :resource="resource"
      @changed="loadModelSchedules(); isAddNewModelScheduleSidebarActive = false"
    ></model-schedule-aside> -->

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Model Schedule (Schedule an action)
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- search filter -->
        <v-col
          cols="12"
          sm="6"
          class="d-flex"
        >
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <!-- <div class="d-flex justify-end flex-wrap">
            <v-btn
              color="primary"
              class="mb-4 me-3"
              :loading="loading"
              @click.stop="addNewResoure"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>Add New ModelSchedule</span>
            </v-btn>
          </div> -->
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- table -->
      <model-schedule-list-table>
      </model-schedule-list-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPencil,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// sidebar
// import ModelScheduleAside from './ModelScheduleAside.vue'

import ModelScheduleListTable from './ModelScheduleListTable'
import useModelScheduleList from './useModelScheduleList'

export default {
  components: {
    ModelScheduleListTable,

    // ModelScheduleAside,
  },
  setup() {
    const {
      loadModelSchedules,
      deleteSchedule: deleteResource,
    } = useModelScheduleList()

    const isAddNewModelScheduleSidebarActive = ref(false)
    const formReady = ref(true)
    const resource = ref(false)

    const setResource = schedule => {
      resource.value = schedule
      isAddNewModelScheduleSidebarActive.value = true
      formReady.value = false
      setTimeout(() => {
        formReady.value = true
      })
    }

    const addNewResoure = () => {
      resource.value = null
      formReady.value = false
      isAddNewModelScheduleSidebarActive.value = true
      setTimeout(() => {
        formReady.value = true
      })
    }

    return {
      resource,
      setResource,
      addNewResoure,

      loadModelSchedules,
      deleteResource,

      // icons
      icons: {
        mdiPencil,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>
