import { date } from '@/@core/utils/filter'
import { destroyModelSchedule, fetchModelSchedules } from '@/api/common/model-schedule'
import { useNotifySuccess, useTableOptions } from '@/composables'
import { computed, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default (defaultQuery = {}) => {
  const modelScheduleListTable = ref([])

  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'Status', value: 'status' },
    { text: 'Action', value: 'action' },
    { text: 'Name', value: 'schedule_name', sortable: false },
    { text: 'Schedules At', value: 'schedules_at' },
    { text: 'Err Message', value: 'message', sortable: false },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
    },
  )
  const searchQuery = ref(cached[1] || '')
  const totalListTable = ref(0)
  const loading = ref(false)
  const totalLocal = ref([])
  const selectedRows = ref([])

  const scheduleOptions = computed(() =>
    modelScheduleListTable.value.map(schedule => ({
      title: `${schedule.code} until ${date(schedule.expires_at)} (${schedule.notes})`,
      value: schedule.code,
    })),
  )

  // fetch data
  const loadModelSchedules = () =>
    fetchModelSchedules(
      useTableOptions(options.value, {
        ...defaultQuery,
        search: searchQuery.value,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data

        modelScheduleListTable.value = records
        totalListTable.value = pagination.total
        totalLocal.value = null

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const deleteSchedule = schedule =>
    destroyModelSchedule(schedule.id)
      .then(loadModelSchedules)
      .then(() => useNotifySuccess({ content: 'Deleted schedule.' }))

  const cachable = [options, searchQuery]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }

      loading.value = true
      selectedRows.value = []
      loadModelSchedules()
      cache()
    }, 300),
  )

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    modelScheduleListTable,
    scheduleOptions,
    tableColumns,
    searchQuery,
    totalListTable,
    loading,
    options,
    totalLocal,
    selectedRows,
    loadModelSchedules,
    deleteSchedule,
  }
}
