import axios from '@axios'

const fetchModelSchedules = params => axios.get('/admin/model-schedules', { params })

const storeModelSchedule = (path, data) => axios.post(`/admin/model-schedules/${path}`, data)

const updateModelSchedule = (path, id, data) => axios.put(`/admin/model-schedules/${path}/${id}`, data)

const destroyModelSchedule = id => axios.delete(`/admin/model-schedules/${id}`)

export { destroyModelSchedule, fetchModelSchedules, storeModelSchedule, updateModelSchedule }
