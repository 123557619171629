<template>
  <v-data-table
    v-model="selectedRows"
    :headers="tableColumns"
    :items="modelScheduleListTable"
    :options.sync="options"
    :server-items-length="totalListTable"
    :loading="loading"
    :hide-default-footer="hideDefaultFooter"
  >
    <!-- actions -->
    <template #[`item.actions`]="{item}">
      <v-menu
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            link
            @click="deleteResource(item)"
          >
            <v-list-item-title>
              <span>Delete</span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'
import useModelScheduleList from './useModelScheduleList'

export default {
  props: {
    defaultQuery: {
      type: Object,
      default: () => {},
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      modelScheduleListTable,
      tableColumns,
      searchQuery,
      totalListTable,
      loading,
      options,
      totalLocal,
      selectedRows,

      loadModelSchedules,
      deleteSchedule: deleteResource,
    } = useModelScheduleList(props.defaultQuery)

    return {
      modelScheduleListTable,
      tableColumns,
      searchQuery,
      totalListTable,

      // categoriesOptions,
      loading,
      options,
      totalLocal,
      selectedRows,

      loadModelSchedules,
      deleteResource,

      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>
